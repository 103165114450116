<template>
    <div>
        <!--AxZShurupjcnList.vue
        {
            pageFlag: true,
            id: "ax_z_shurupjcn",
            title: "",
            name: "ax_z_shurupjcn",
            icon: "el-icon-document",
            component: "views/zb/AxZShurupjcnList.vue",
            componentdetail: "views/zb/AxZShurupjcnDetail.vue",
            visible: true
        }
        -->
        <el-container>
            <el-main>
                <el-button-group>
                    <el-button type="" size="mini" @click="syslistquery">查询</el-button>
                    <el-upload
                        size="small"
                        class="filter-item"
                        name="excelFile"
                        ref="upload"
                        action="/zb/axZShurupjcn/upload"
                        :http-request="uploadUrl"
                        :before-upload="beforeAvatarUpload"
                        accept=".xlsm,.xlsx,.xls"
                        :show-file-list="false"
                        :file-list="fileList">
                        <el-button size="mini" type="">导入</el-button>
                    </el-upload>
                    <!-- <el-button type="" size="mini" @click="syslistadd">新增</el-button> -->
                </el-button-group>
                <vxe-form size="mini">
                    <vxe-form-item title="类型" field="kind" :item-render="{}" style="width:60px;">
                        <vxe-input v-model="p.kind" size="mini" placeholder="类型"></vxe-input>
                        
                    </vxe-form-item>
                    <vxe-form-item title="班组" field="bz" :item-render="{}" style="width:60px;">
                        <vxe-input v-model="p.bz" size="mini" placeholder="班组"></vxe-input>
                        
                    </vxe-form-item>
                </vxe-form>
                <vxe-table :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow highlight-hover-row size="mini" :data="rs" :height="meta.h">
                    <vxe-table-column field="kind" title="类型" width="250"></vxe-table-column>
                    <vxe-table-column field="bz" title="班组" width="250"></vxe-table-column>
                    <vxe-table-column field="daycn" title="每天产能（数量）" width="120"></vxe-table-column>
                    <vxe-table-column field="monthcn" title="每月产能（数量）" width="120"></vxe-table-column>
                    <vxe-table-column field="efficiency"  title="效率" width="95">
                        <template slot-scope="{ row }">{{ (row.efficiency * 100) }}%</template>
                    </vxe-table-column>
                    <vxe-table-column field="inputdate"  title="日期" width="100"></vxe-table-column>
                    <!-- <vxe-table-column fixed="right" width="120" title="操作">
                        <template #default="{row, rowIndex }">
                            <a @click="syslistdeleteone(row)">删除 | </a>
                            <a @click="syslistqueryone(row)">详细</a>
                        </template>
                    </vxe-table-column> -->
                </vxe-table>
                <vxe-pager :align="'left'" background :current-page.sync="p1.page.current" :page-size.sync="p1.page.size" :total="p1.page.total" @page-change="pagechange" :page-sizes=[100,500,1000,10000] :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
                </vxe-pager>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Vue from "vue";
    export default {
        name: "ax_z_shurupjcnlist",
        extends: ax.control1(),
        data() {
            return {
                p: { kind: "",bz:"" },
                model: "zb",
                settings: "axZShurupjcn",
                vouchertype: "list",
                fileList: [],
            }
        },
        methods: {
            batteryformatter(row)
            {
                return row.efficiency*100 + "%";
            },
            //上传前对上传文件的校验
            beforeAvatarUpload(file) {

                const extension = file.name.split(".")[1] === "xls";
                const extension2 = file.name.split(".")[1] === "xlsx";
                const extension5 = file.name.split(".")[1] === "xlsm";
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!extension && !extension2 && !extension5) {
                    this.$message({
                        message: "上传模板只能是 xls、xlsx格式!",
                        type: "error"
                    });
                }
                if (!isLt2M) {
                    this.$message({
                        message: "上传模板大小不能超过 10MB!",
                        type: "error"
                    });
                }
                return extension || extension2 || extension5 || (extension4 && isLt2M);
            },
            uploadUrl: function(param) {
                this.isImported = true;
                var form = new FormData();
                form.append("file", param.file);
                form.append("aa", "aa");

                //使用post方式调用后台接口 
                let file = form.get('file');
                let _this = this;
                ax.base.post('/zb/axZShurupjcn/upload', form).then(data => {
                    console.log(data);
                    _this.$XModal.message({ message: data.data, status: 'success' })
                    //如果每次上传一个文件，不清空可能会产生一些错误
                    // this.fileList = [];
                    _this.syslistquery();
                }).catch(data => {
                    //如果每次上传一个文件，不清空可能会产生一些错误
                    // this.fileList = [];
                    _this.$XModal.message({ message: data.data, status: 'error', duration: 10000 })
                });

            },
        }
    }
</script>